import { Angles } from "@vertikal/e-prospera.ui.angles";
import Box from "@vertikal/e-prospera.ui.box";
import Button from "@vertikal/e-prospera.ui.button";
import Text from "@vertikal/e-prospera.ui.text";
import { format, parseISO } from "date-fns";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import { API } from "../../api/api";
import GlobalSettings from "../../components/GlobalSettings";
import MainMenu from "../../components/MainMenu/MainMenu";
import {
  AccessRenewalActions,
  AccessRenewalCard,
  AccessRenewalContainer,
  SuccessIcon,
} from "../../styles/access-renewal.styles";

const AccessRenewal = (props) => {
  const {
    params: { id },
  } = props;

  const [pendingPeriod, setPendingPeriod] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [updatedPeriod, setUpdatedPeriod] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const req = await fetch(
          `${API}/resident-register/public/access-periods/${id}`
        );
        const res = await req.json();
        if (!req.ok) {
          throw new Error(req.status);
        }
        setPendingPeriod(res);
      } catch (error) {
        window.location.href = "/404";
      }
    }
    fetchData();
  }, [id]);

  const renewAccess = async () => {
    setUpdating(true);
    try {
      const req = await fetch(
        `${API}/resident-register/public/access-periods/${id}`,
        { method: "PATCH" }
      );
      const res = await req.json();
      if (!req.ok) {
        throw new Error(req.status);
      }
      setUpdating(false);
      setPendingPeriod(null);
      setUpdatedPeriod(res);
    } catch (error) {
      setUpdating(false);
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>ePróspera</title>
      </Helmet>
      <GlobalSettings />
      <MainMenu>
        <AccessRenewalContainer>
          <Angles noHighlight></Angles>
          <AccessRenewalCard>
            {pendingPeriod && (
              <>
                <Box marginTop="auto">
                  <Text variant="h4">
                    <Trans>
                      residency.renew_access_modal.renewal_for_60_days
                    </Trans>
                  </Text>
                  <Text color="gray">
                    {format(
                      parseISO(pendingPeriod.period.startDate),
                      "MM/dd/yyyy"
                    )}{" "}
                    -{" "}
                    {format(
                      parseISO(pendingPeriod.period.endDate),
                      "MM/dd/yyyy"
                    )}
                  </Text>
                </Box>
                <Text>
                  <Trans>residency.renew_access_modal.renewal_info</Trans>
                </Text>

                <AccessRenewalActions>
                  <Button
                    id="access-renewal-go-back"
                    variant="ghost"
                    color="gray"
                    icon="arrow_back"
                    onClick={() => (window.location.href = "/")}
                  >
                    <Trans>button.go_back</Trans>
                  </Button>
                  <Button
                    id="access-renewal-confirm"
                    color="success"
                    loading={updating}
                    onClick={renewAccess}
                  >
                    <Trans>common.confirm</Trans>
                  </Button>
                </AccessRenewalActions>
              </>
            )}
            {updatedPeriod && (
              <>
                <SuccessIcon></SuccessIcon>
                <Text variant="h4">
                  <Trans>
                    residency.renew_access_modal.renewal_was_successful
                  </Trans>
                </Text>
                <Text>
                  <Trans
                    components={{
                      startDate: format(
                        parseISO(updatedPeriod.period.startDate),
                        "MM/dd/yyyy"
                      ),
                      endDate: format(
                        parseISO(updatedPeriod.period.endDate),
                        "MM/dd/yyyy"
                      ),
                    }}
                  >
                    residency.renew_access_modal.renewal_successful_info
                  </Trans>
                </Text>
              </>
            )}
          </AccessRenewalCard>
        </AccessRenewalContainer>
      </MainMenu>
    </>
  );
};

export default AccessRenewal;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
