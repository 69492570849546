import colors from '@vertikal/e-prospera.styles.colors';
import Icon from '@vertikal/e-prospera.ui.icon';
import Paper from '@vertikal/e-prospera.ui.paper';
import styled from 'styled-components';
import HomePageHeroMap from '../images/homepage_hero_map.png';
export const AccessRenewalContainer = styled.div`
  height: calc(100vh - 10rem);
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    background-color: ${colors.accent.c900};
    background-image: ${`url("${HomePageHeroMap}")`};
    background-repeat: no-repeat;
    background-position: 25% 50%;
    top: 0;
    height: 50%;
    width: 100%;
  }
`;

export const SuccessIcon = styled(Icon).attrs(() => ({ name: 'check' }))`
  font-size: 2rem;
  color: ${colors.success.c600};
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.success.c200};
  border-radius: 50%;
`;

export const AccessRenewalCard = styled(Paper).attrs(() => ({ elevation: 3, borderRadius: 8 }))`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 25.5rem;
  width: 28.75rem;
  box-sizing: border-box;
`;

export const AccessRenewalActions = styled.div`
  width: calc(100% + 1.5rem);
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-left: -1.5rem;
`;
